import React, { useEffect } from 'react';
import ImgGrid from '../../components/ImgGrid';
import Nav from '../../components/Nav';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';

import { useService } from '../../providers/ServiceProvider';


const Wedding = () => {
  const { serviceData, setService } = useService();
const navigate = useNavigate()
  useEffect(() => {
    document.title = `JemsPhotography - ${serviceData?.title || 'Wedding'}`;
  }, [serviceData]);

  const exploreMore = () => {
    // Redirect to the specified route and pass imgData as state
    navigate(serviceData.route, { state: { imgData: serviceData.imgData } });
  };
  console.log(serviceData);
  const width = window.innerWidth;
  useEffect(()=>{
    window.scrollTo(0,0);
    if(serviceData.backgroundImg==""){
      navigate("/");
    }
  },[])
  return (
    <div>
      <header
        id="page-title"
        className='servicebackgrandV'
      >
        <Nav />
        <div
          style={{
            // Styles for the ::after pseudo-element
            position: 'relative',
          }}
          className="page-title-content lato-light"
        >
          {serviceData && (
            <div
              className="page-title-after lato-light"
            >
              {serviceData.title}
            </div>
          )}
        </div>
      </header>
      <main style={{marginTop:"-50px",padding:0}}>
          <ImgGrid itemData={serviceData.imgData}  />
      </main>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Wedding;