import React, { createContext, useEffect, useState } from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Wedding from "./pages/Service/Wedding";
import { ServiceProvider } from "./providers/ServiceProvider";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/admin/Dashboard";
import SliderModification from "./pages/admin/SliderModification";
import EditAboutSection from "./pages/admin/EditAboutSection";
import ServiceList from "./pages/admin/ServiceList";
import ServiceForm from "./pages/admin/ServiceForm";
import AddRemoveGridImgs from "./pages/admin/AddRemoveGridImgs";
import Splash from "./pages/Home/Splash";
import LoadingBar from "react-top-loading-bar";
import Login from "./pages/admin/Login";
import Tost from "./pages/Home/Tost";
 
export const MyContext = createContext();
const App = () => { 
  const [isLogin,setIsLogin]=useState(false);
  const [gservices,setGservices]=useState([]);
  const [mainSlider,setMainSlider]=useState(null);
  const [services,setServices]=useState(null);
  const [workslider,setWorkSlider]=useState(null);
  const [isVisible,setIsVisible]=useState(true);
  const [showTost,setShowTost]=useState(false);
  const [inFlow,setInFlow]=useState(false);
  const showLoader = (time)=>{
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
  }, time);
  }
  const showTostfun = (title,time=3000,isSuc=true)=>{
    setShowTost({title,isSuc});
    setTimeout(() => {
      setShowTost(false);
    }, time);
  }
  setTimeout(() => {
      setIsVisible(false);
  }, 4000);
  // useEffect(()=>{
  //   const login = window.localStorage.getItem("isLogin");
  //   if(login){
  //     setIsLogin(true);
  //   }else{
  //     setIsLogin(false);
  //   }
  // },[])
  return (
    <MyContext.Provider value={{
      isLogin,setIsLogin,
      gservices,setGservices,
      mainSlider,setMainSlider,
      services,setServices,
      workslider,setWorkSlider,
      showLoader,showTostfun
      }}>
      {showTost && <Tost showTost={showTost}/> }
      {isVisible && <Splash/> }
      <Router>
        <ServiceProvider>
          <Routes>
            <Route path="/" element={<Home setInFlow={setInFlow} />} /> 
            {/* <Route path="/home" element={<Home />} />  */}
            <Route path="/service/" element={<Wedding />} />
            <Route
              path="/dashboard" 
              // path="/"
              element={
                <div id="app" className="rw">
                  <Sidebar />
                  <main className="db-fix-h">
                    <Login/>
                  </main>
                </div>
              }
            />
            <Route
              path="/dashboard/modify-slider"
              element={
                <div id="app" className="rw">
                  <Sidebar />

                  <main className="db-fix-h">
                    <SliderModification />
                  </main>
                </div>
              }
            />
            <Route
              path="/dashboard/modify-about"
              element={
                <div id="app" className="rw">
                  <Sidebar />

                  <main className="db-fix-h">
                    <EditAboutSection />
                  </main>
                </div>
              }
            />
            <Route
              path="/dashboard/modify-service"
              element={
                <div id="app" className="rw">
                  <Sidebar />

                  <main className="db-fix-h">
                    <ServiceList />
                  </main>
                </div>
              }
            />
            <Route
              path="/dashboard/add-service"
              element={
                <div id="app" className="rw">
                  <Sidebar />

                  <main className="db-fix-h">
                    <ServiceForm />
                  </main>
                </div>
              }
            />
            <Route
              path="/dashboard/manage-grid"
              element={
                <div id="app" className="rw">
                  <Sidebar />

                  <main className="db-fix-h">
                    <AddRemoveGridImgs />
                  </main>
                </div>
              }
            />
          </Routes>
        </ServiceProvider>
      </Router>
    </MyContext.Provider>
  );
};

export default App;
