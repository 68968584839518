import React from 'react'

function Tost({showTost}) {
    const isSuc = showTost?.isSuc;
  return (
    <div style={{zIndex:200,position:'absolute',color:'white',top:"20px",right:"20px",padding:'16px',backgroundColor:isSuc?'Green':'Red',width:"350px"
    }}>{showTost?.title}</div>
  )
}

export default Tost