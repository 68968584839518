// ServiceForm.jsx

import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Container, Grid, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './Form.css';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../App';

const ServiceForm = ({ serviceToEdit ,handleCloseEditDialog,getServices}) => {
  const [service, setService] = useState({
    serviceName: serviceToEdit?.title,
    serviceDescription: serviceToEdit?.text,
    poster: serviceToEdit?.img,
    date: serviceToEdit?.date ? serviceToEdit?.date : Date.now(),
    id: serviceToEdit?._id,
    samplePhotos: serviceToEdit?.imglist
  });
  const backend = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;
  const nav = useNavigate()
  const {isLogin,showTostfun}=useContext(MyContext);


  const handleInputChange = (field, value) => {
    setService({ ...service, [field]: value });
  };

  const handlePosterChange = (event) => {
    setService({ ...service, poster: event.target.value});
  };


  const handleSubmit =async  (e) => {
    e.preventDefault()
    // Handle form submission logic here
    try {
      const url = `${backend}/${serviceToEdit? "updateService":"services"}`;
      // console.log("url",url)
      const dt = await fetch(url,{
        method:"POST",
        headers:{
          "Content-type": "application/json",
          token:token,
        },
        body:JSON.stringify({
          service:service
        })  
      })
      const fr = await dt.json();
      if(fr.status=="ok"){
        serviceToEdit && getServices();
        serviceToEdit ? handleCloseEditDialog(): nav("/dashboard/modify-service");
        showTostfun("Edit Successfully...",3000);
      }else{
        showTostfun("not updated...",3000,false);
      }
    } catch (error) {
      showTostfun("not updated",3000,false);
    }
  };
  useEffect(()=>{
    !isLogin && nav("/dashboard");
  },[])
  
  return (
    <Container>
      <Paper elevation={3} className="paper">
        <Typography variant="h5" gutterBottom>
          {serviceToEdit ? 'Edit Service' : 'Add New Service'}
        </Typography>
        <form onSubmit={handleSubmit  }>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Service Name"
                fullWidth
                required
                value={service.serviceName}
                onChange={(e) => handleInputChange('serviceName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Service Description"
                fullWidth
                required
                multiline
                rows={4}
                value={service.serviceDescription}
                onChange={(e) => handleInputChange('serviceDescription', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Add Image Link"
                fullWidth
                required
                multiline
                rows={1}
                value={service.poster}
                onChange={handlePosterChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Button variant="contained" className="btn-m" type="submit">
                {serviceToEdit ? 'Update' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ServiceForm;
