import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import { MyContext } from '../../App';
import { useNavigate } from 'react-router-dom';

const EditAboutSection = () => {
  const [aboutSection, setAboutSection] = useState({
    aboutPosterImg: '',
    description: ['','','',''], // Array to store multiple paragraphs
  });
  const nav = useNavigate()
  const [aboutSecId,setAboutSecId]=useState(null);
  const {isLogin,showTostfun}=useContext(MyContext);
  const handleInputChange = (field, value) => {
    setAboutSection({ ...aboutSection, [field]: value });
  };

  const handlePosterChange = (event) => {
    setAboutSection({ ...aboutSection, aboutPosterImg: event.target.value });
  };
  const backend = process.env.REACT_APP_BACKEND;
  const token = process.env.REACT_APP_TOKEN;

  
  const handleDescriptionChange = (index, value) => {
    const updatedDescription = [...aboutSection.description];
    updatedDescription[index] = value;
    setAboutSection({ ...aboutSection, description: updatedDescription });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dt = await fetch(`${backend}/updateAbout`,{
        method:"POST",
        headers:{
          'token':token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: aboutSecId,
          data: aboutSection
        })
      })
      // // console.log(dt);
      const dtparse= await dt.json();
      if(dtparse.status=="ok"){
        showTostfun("Edit Successfully...",3000);
      }else{
        showTostfun("Data Not saved",3000,false);
      }
    } catch (error) {
      showTostfun("Data Not saved",3000,false);
    }
  };
  const getAboutSection = async ()=>{
    const dt = await fetch(`${backend}/getAboutSection`, {
      method:"GET",
      headers:{
        'token':token,
        'Content-Type': 'application/json'
      }
    })
    const fr = await dt.json();
    if(fr.status="ok"){
      setAboutSection(fr.dt);
      setAboutSecId(fr.id);
    }
  }
  useEffect(()=>{
    getAboutSection();
    if(!isLogin){
      nav("/")
    }
  },[])
  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Edit About Section
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <label htmlFor="aboutPosterInput">Add About Poster Image</label> */}
              {/* <input
                type="text"
                accept="image/*"
                id="aboutPosterInput"
                onChange={handlePosterChange}
              /> */}
              <TextField
                label="About Poster Image URL"
                fullWidth
                multiline
                rows={1}
                value={aboutSection.aboutPosterImg}
                onChange={handlePosterChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Heading"
                fullWidth
                multiline
                rows={4}
                value={aboutSection.description[0]}
                onChange={(e) => handleDescriptionChange(0, e.target.value)}
              />
            </Grid>
            {aboutSection?.description.slice(1).map((paragraph, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  label={`Paragraph ${index + 1}`}
                  fullWidth
                  multiline
                  rows={4}
                  value={paragraph}
                  onChange={(e) => handleDescriptionChange(index + 1, e.target.value)}
                />  
              </Grid>
            ))}
            {/* <Grid item xs={12}>
              <Button variant="outlined" onClick={handleAddParagraph}>
                Add New Paragraph
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default EditAboutSection;
