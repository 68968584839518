import React, { useEffect, useState } from "react";
import "../styles/Nav.css";
import { Link, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Nav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const loc = useLocation();
  // console.log(loc.pathname);
  console.log("nav done;");



  
  return (
    <nav style={{ zIndex: "2" }}>
      <div className="nv-r">
        {/* <Link to="/">Home</Link> */}
        {loc.pathname == "/" ? (
          <>
            <a href="/#about">About</a>
            <a href="/#service">Service</a>
            <Link to="/" className="nv-l">
              <img src="/jemsLogo.png" alt="Logo" />
              {/* <img src="/imgs/JEMS.png" alt="Logo" /> */}
              {/* <span>PHOTOGRAPHY</span> */}
            </Link>
            <a href="/#work">Our Work</a>
            <a href="/#contact">Contact</a>
          </>
        ) : (
          <>
            <Link to="/#about">About</Link>
            <Link to="/#service">Service</Link>
            <Link to="/" className="nv-l">
              <img src="/jemsLogo.png" alt="Logo" />
            </Link>
            <Link to="/#work">Our Work</Link>
            <Link to="/#contact">Contact</Link>
          </>
        )}
        {/* <Link to="/dashboard">Dashboard</Link> */}
      </div>
      <Link to="/" className="nv-l-hid">
          <img src="/imgs/JEMS.png" alt="Logo" />
      </Link>
      <IconButton onClick={toggleMenu} className="menu-button">
        {isMenuOpen ? (
          <CloseIcon className="hamburger" />
        ) : (
          <MenuIcon className="hamburger" />
        )}
      </IconButton>
      <div
        className={`hidden-side-nav ${isMenuOpen ? "show" : ""}`}
        onClick={closeMenu}
      >
        <div className="nv-r-h">
          <div className="clo">
            <IconButton onClick={closeMenu}>
              <CloseIcon className="close" />
            </IconButton>
          </div>
          <Link to="/">Home</Link>
          {loc.pathname == "/" ? (
            <>
              <a href="/#about">About</a>
              <a href="/#service">Service</a>
              <a href="/#work">Our Work</a>
              <a href="/#contact">Contact</a>
            </>
          ) : (
            <>
              <Link to="/#about">About</Link>
              <Link to="/#service">Service</Link>
              <Link to="/#work">Our Work</Link>
              <Link to="/#contact">Contact</Link>
            </>
          )}
          {/* <Link to="/dashboard">Dashboard</Link> */}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
