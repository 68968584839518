import React from "react";
import "../styles/Footer.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';import TwitterIcon from '@mui/icons-material/Twitter';import GoogleIcon from '@mui/icons-material/Google';import SendIcon from '@mui/icons-material/Send';
import { Link } from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <footer>
<div id="ft-lg">
  <img src="/imgs/JEMS.png"
  alt="" />
</div>
<b>
  Jems Studio
</b>
<div id="g-para">
  <p>
    <LocationOnIcon id="xl-ico" />  Patel girls hostel road Jamjodhpur,
   
        <br /> Gujarat  360530
    </p>
    <p>
    <CallIcon id="xl-ico" />  9925858350, 9067888188
    </p>
    <p>
    <EmailIcon id="xl-ico" />  jemsphotography.in@gmail.com
    </p>
</div>
<div id="soc">
  <a href="https://www.facebook.com/jemsphotography.in/" target="_blank" className="s-ico">
    <FacebookIcon/>
  </a>
  <a href="https://www.instagram.com/jems_photography.in/" target="_blank" className="s-ico">
    <InstagramIcon/>
  </a>
  <a href="https://www.youtube.com/@jems.studio" target="_blank" className="s-ico">
    <YouTubeIcon/>
  </a>
</div>
<div id="cp">
  Copyright © 2024, Qubit Tech
</div>
    </footer>
  );
};

export default Footer;
