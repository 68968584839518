import React, { useEffect, useRef, useState } from 'react'
import LoadingBar from 'react-top-loading-bar'

function Splash() {
    const ref = useRef();
    useEffect(()=>{
      ref.current.continuousStart()
    },[])
  return (
   <> <LoadingBar 
      style={{zIndex:'10'}}
      color='#d37e05' 
      ref={ref}
      height={4}
   />
   <div className="loaderr">
    <img src="/imgs/loader-min.png" alt="Splash" />
   </div>
  </>
  )
}

export default Splash
