import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import '../styles/ImgGrid.css'; // Import your CSS file

const ImgGrid = ({ itemData }) => {
  const [img,setImg]=useState(null);
  function openI(item){
    setImg(item);
  }
  return (<>
    {img && <div style={{position:'fixed',top:0,left:0,background:"black",height:'100dvh',width:'100dvw',display:'flex',justifyContent:'center',alignItems:'center',zIndex:'10'}}>
      <img  src={img} style={{maxWidth:'100dvw',maxHeight:'100dvh'}}/> 
      <button style={{position:'fixed',top:"20px",right:'20px',borderRadius:'100%',color:'white',backgroundColor:'black',fontSize:'2rem'}} onClick={()=>setImg(null)}>X</button>
    </div> }
    <Box sx={{ width: '100%', minHeight: 829 }} className='imgGd'>
      <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
        {itemData &&
          itemData.map((item, index) => (
            <div key={index}>
              <img
                src={`${item}`}
                alt={`Image ${index + 1}`}
                loading="lazy"
                onClick={()=>openI(item)}
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  display: 'block',
                  width: '100%',
                }}
              />
            </div>
          ))}
      </Masonry>
    </Box>
  </>
    );
};

export default ImgGrid;
